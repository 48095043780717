<template>
  <div class="">
    <address-list></address-list>
  </div>
</template>

<script>
import AddressList from "../../../components/adderss/addressList.vue";
export default {
  name: "user-addresses",
  components: { AddressList },

  beforeRouteEnter(to, from, next) {
    if (!localStorage.getItem("userToken")) {
      next({ name: "auth" });
    } else next();
  },
};
</script>




